import React,  { useState }  from 'react';
import '../helpers/suppress-install-prompt';
import Slider from 'react-slick';
import Layout from "../components/Layout";

import { Container, Row, Col, Button, CardDeck, Collapse, CardBody, Card, Alert, CardImg, CardFooter, Nav, NavLink } from 'reactstrap';

import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import scrollToElement from '../helpers/scrollToElement';
import { GatsbyImage } from "gatsby-plugin-image";

import SaleProducts from "../components/SaleProduct/SaleProducts";
import SaleProductProvider from "../SaleProduct/SaleProductContext";

import { graphql } from "gatsby";

import headerMobile from '../images/diet/header_mobile.jpg';

import appIcon from '../images/diet/hpba_app_icon.jpg';
import elwira from '../images/diet/elwira.jpg';
import kasia from '../images/diet/kasia.jpg';
import martyna from '../images/diet/martyna.jpg';

import icon1 from '../images/diet/icon_1_individul.svg'
import icon2 from '../images/diet/icon_2_change.svg'
import icon3 from '../images/diet/icon_3_special_meal.svg'
import icon4 from '../images/diet/icon_4_four_diets.svg'
import icon5 from '../images/diet/icon_5_online_care.svg'
import icon6 from '../images/diet/icon_6_shopping.svg'
import icon7 from '../images/diet/icon_7_training.svg'
import icon8 from '../images/diet/icon_8_team.svg'
import icon9 from '../images/diet/icon_9_stats.svg'
import icon10 from '../images/diet/icon_10_straining_stuff.svg'
import icon11 from '../images/diet/icon_11_hydration.svg'
import icon12 from '../images/diet/icon_12_offline.svg'

import ania from '../images/diet/ania.png';

import makaron from '../images/diet/makaron.jpg';
import woda from '../images/diet/woda.png';

import mozdziez from '../images/diet/mozdziez.png';
import pomidory from '../images/diet/pomidory.jpg';

import salatka from '../images/diet/salatka.jpg';
import przyprawy from '../images/diet/przyprawy.jpg';
import skladniki from '../images/diet/skladniki.jpg';

import powerTriangle from '../images/diet/power-triangle.svg';

import diet11 from '../images/diet/diet/j_1_1.jpg';
import diet12 from '../images/diet/diet/j_1_2.jpg';
import diet13 from '../images/diet/diet/j_1_3.jpg';
import diet14 from '../images/diet/diet/j_1_4.jpg';

import diet21 from '../images/diet/diet/j_2_1.jpg';
import diet22 from '../images/diet/diet/j_2_2.jpg';
import diet23 from '../images/diet/diet/j_2_3.jpg';
import diet24 from '../images/diet/diet/j_2_4.jpg';
import diet25 from '../images/diet/diet/j_2_5.jpg';

import diet31 from '../images/diet/diet/j_3_1.jpg';
import diet32 from '../images/diet/diet/j_3_2.jpg';
import diet33 from '../images/diet/diet/j_3_3.jpg';
import diet34 from '../images/diet/diet/j_3_4.jpg';
import diet35 from '../images/diet/diet/j_3_5.jpg';

import diet41 from '../images/diet/diet/j_4_1.jpg';
import diet42 from '../images/diet/diet/j_4_2.jpg';
import diet43 from '../images/diet/diet/j_4_3.jpg';
import diet44 from '../images/diet/diet/j_4_4.jpg';
import diet45 from '../images/diet/diet/j_4_5.jpg';

import iconKcal from '../images/diet/icon_kcal.svg';
import iconTime from '../images/diet/icon_time.svg';
import arrow from '../images/homepage-new/arrow.svg';

import video from '../images/diet/diet_video.mp4'
import { size } from 'lodash';


const Page = ({ data }) => {
    const [isOpen, setIsOpen] = useState(1);
    const [isActive, setIsActive] = useState(1);
    
    const toggle = number => {
        if(isOpen === number){
            setIsOpen(0);    
        }
        else{
            setIsOpen(number);    
        }
    }

    const toggleActive = number => {
        setIsActive(number);
    }

    const bgImage = convertToBgImage(data.hero.childImageSharp.gatsbyImageData)

    const slider = React.createRef();

    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                },
            },
        ],
    };

    const handlePrev = () => {
        slider.current.slickPrev();
    }

    const handleNext = () => {
        slider.current.slickNext();
    }

    return (
        <Layout page="diet" showMenu={true}>
            <img src={headerMobile} className="img-fluid d-md-none" alt="" />
            <BackgroundImage
                Tag="section"
                className="hero"                
                {...bgImage}
            >
                <Container>
                    <Row>
                        <Col md="6">
                            <h1>Zrób to dla siebie!</h1>
                            <h2>Indywidualnie dopasowany jadłospis i treningi</h2>
                            <h3>zawsze przy tobie</h3>
                            <Button onClick={() =>
                                scrollToElement(
                                    document.getElementById('buy')
                                )
                            }>
                                Chcę zacząć zmiany
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </BackgroundImage>
    
            <section className="diet1">
                <Container>
                    <div className="box">
                        <img src={appIcon} alt="" />
                        <div>
                            <strong>Aplikacja</strong><br/>DIET & TRAINING<br/>by ANN
                        </div>
                    </div>
                    <h3>Już ponad 3 300 000 osób zaczęło zmieniać swoje <br className="d-none d-md-block" />nawyki z aplikacją Diet&nbsp;&&nbsp;Training&nbsp;by&nbsp;Ann</h3>
                </Container>
            </section>
    
            <section className="diet2">
                <Container>
                    <h2 className="text-center">Poznaj nasz Healthy Team!</h2>
                    <h3 className="text-center">Zobacz, jak wyglądała ich droga do wymarzonej sylwetki.</h3>

                    <Row className="align-items-center">
                        <Col md="5">
                            <div className="position-relative">
                                <div className="circle1" />
                                <div className="circle2" />
                                <img src={elwira} className="img-fluid" alt="" />
                            </div>
                            
                        </Col>
                        <Col md={{size: 6, offset: 1}}>
                            <h4>Elwira</h4>
                            <h3>Jestem szczęśliwa, świadoma, zmotywowana ale przede wszystkim zdrowa.</h3>
                            <p>Łatwo tłumaczyłam niezdrowe jedzenie i podjadanie brakiem czasu, zmęczeniem. Nadszedł moment, w którym patrząc w lustro powiedziałam sobie dość! Zrobiłam to dla siebie i mojego synka. Pierwszym krokiem (...) było ściągnięcie aplikacji. Nie ukrywam, początki były trudne ale wsparcie najbliższych procentowało. Wspólnie robiliśmy zakupy z aplikacją, przygotowywaliśmy posiłki z aplikacji oraz treningi (...) Posiłki pyszne, przekąski bardzo smaczne a treningi okazały się być przyjemnością. (...) Nawet nie wiem kiedy minął rok a moja waga pokazała 50 kg mniej!! Jestem szczęśliwa, świadoma, zmotywowana ale przede wszystkim zdrowa.</p>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Col md={{size: 5, offset: 1, order: 2}}>
                            <div className="position-relative">
                                <div className="circle3" />
                                <div className="circle4" />
                                <img src={martyna} className="img-fluid" alt="" />
                            </div>
                        </Col>
                        <Col md={{size: 6, order: 1}}>
                            <h4>Martyna</h4>
                            <h3>Teraz czuje się świetnie, nie tylko fizycznie, ale w końcu czuję spokój psychiczny.</h3>
                            <p>Moja przygoda z aplikacją zaczęła się w tym roku (...) ściągnęłam aplikacje i zakochałam się! To strzał w 10! (...) Teraz czuje się świetnie, nie tylko fizycznie, ale w końcu czuję spokój psychiczny, ponieważ dzięki zdrowszym nawykom żywieniowym i ćwiczeniom czuję sie wewnętrznie lepiej. Nie wyobrażam sobie dnia bez dziewczyn, które codziennie przez wiadomości instagramowe motywują do ćwiczeń i treningów z APKĄ!</p>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Col md="5">
                            <div className="position-relative">
                                <div className="circle5" />
                                <div className="circle6" />
                                <img src={kasia} className="img-fluid" alt="" />
                            </div>
                            
                        </Col>
                        <Col md={{size: 6, offset: 1}}>
                            <h4>Kasia</h4>
                            <h3>Straciłam na wadze 9 kg, pewność siebie wzrosła o 100000%.</h3>
                            <p>Moja przygoda z Diet & Training by Ann, (chociaż to nie do końca trafne określenie, „przygoda” kojarzy nam się z czymś krótkotrwałym, a to zdecydowanie jest związek na lata) zaczęła się rok temu. Miałam za sobą dwie ciąże w bardzo krótkim odstępie czasu. Za drugim razem kilogramów było więcej. Silną wolę i determinację miałam, ale brakowało mi narzędzia w postaci tej aplikacji. Potrzebowałam jadłospisu, który dostosuje się do mojego smaku i planu treningowego, który nie będzie monotonny i pomoże zgubić nadprogramowe kilogramy i wyrzeźbić ciało. Straciłam na wadze 9 kg, pewność siebie wzrosła o 100000%.</p>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button
                            onClick={() =>
                                scrollToElement(
                                    document.getElementById('buy')
                                )
                            }>
                            Teraz Twoja kolej!
                        </Button>
                    </div>
                </Container>
            </section>

            <section className="diet3">
                <Container>
                    <Row>
                        <Col xs="6" md="2">
                            <img src={icon1} alt="" />
                            <p>Indywidualnie dopasowany jadłospis</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon2} alt="" />
                            <p>Wymiana posiłków i produktów</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon3} alt="" />
                            <p>Jadłospisy okolicznościowe</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon4} alt="" />
                            <p>4 rodzaje diety do wyboru</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon5} alt="" />
                            <p>Opieka dietetyków online</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon6} alt="" />
                            <p>Interaktywna lista zakupów</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon7} alt="" />
                            <p>Ponad 90 programów treningowych</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon8} alt="" />
                            <p>Trzech trenerów do wyboru</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon9} alt="" />
                            <p>Pomiary i śledzenie postępów</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon10} alt="" />
                            <p>Treningi ze sprzętem lub bez</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon11} alt="" />
                            <p>Monitorowanie nawodnienia</p>
                        </Col>
                        <Col xs="6" md="2">
                            <img src={icon12} alt="" />
                            <p>Treningi dostępne offline</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="diet4">
                <Container>
                    <Row className="align-items-center">
                        <Col md="7">
                            <h2>Dopasowana dieta i treningi</h2>
                            <p>Zanim otrzymasz swój jadłospis, opowiedz trochę o sobie. Twoja aktualna waga, wzrost, styl życia, czy cel - to wszystko ma wpływ na kaloryczność i rozkład makroskładników w Twojej diecie. Tylko dzięki takiemu indywidualnie dopasowanemu planowi żywienia osiągniesz efekty, na które liczysz i zrobisz to w sposób bezpieczny dla zdrowia.</p>
                        </Col>
                        <Col md="5" className="text-center">
                            <div className="circle" />
                            <img src={ania} alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="diet5">
                <Container>
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <h2>Ćwicz razem z nami!</h2>
                            <p>W aplikacji znajdziesz ponad 90 programów treningowych - wybierasz taki, jaki nejlepiej odpowiada Twoim aktualnym potrzebom. Z aplikacją możesz ćwiczyć kiedy i jak długo chcesz. Twój trening, Twoje zasady! Czekają treningi dopasowane do każdego - również dla osób uprawiających sport, kobiet w trakcie i po ciąży, mężczyzn, par czy osób dojrzałych!</p>

                        
 
                        </Col>
                    </Row>
                    <div className="slider-container">
                        
                        <Slider ref={slider} {...settings}>
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                        <GatsbyImage image={data.anna.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Anna Lewandowska</h4>
                                        <p>Treningi funkcjonalne</p>
                                    </div>
                                    <p>Wielokrotna medalistka mistrzostw świata, Europy i Polski w karate tradycyjnym. Była reprezentantka Polski w karate. Dyplomowana trenerka fitness i TRX. Z wykształcenia manager sportowy i dietetyk.</p>
                                </div>
                            </div>
        
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                    <GatsbyImage image={data.jj.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Joanna Jędrzejczyk</h4>
                                        <p>Treningi z elementami sztuk walki</p>
                                    </div>
                                    <p>Reprezentantka Polski w boksie, kick-boxingu, muay thai oraz mieszanych sztukach walki (MMA). Trzykrotna zawodowa mistrzyni świata. Pierwsza Polka w prestiżowej organizacji Ultimate Fighting Championship.</p> 
                                </div>
                            </div>
                            
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                    <GatsbyImage image={data.mariola.childImageSharp.gatsbyImageData} alt=""/>    
                                        <h4>Mariola Bojarska - Ferenc</h4>
                                        <p>Treningi dla dojrzałych</p>
                                    </div>
                                    <p>Absolwentka Akademii Wychowania Fizycznego w Warszawie. Była członkini kadry narodowej w gimnastyce artystycznej. Instruktorka fitness z wieloletnim doświadczeniem, członkini najważniejszych światowych organizacji fitness. Swoje treningi kieruje przede wszystkim do osób dojrzałych.</p>
                                </div>
                            </div>
        
                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                    <GatsbyImage image={data.ewelina.childImageSharp.gatsbyImageData} alt=""/>
                                        <h4>Ewelina Godlewska</h4>
                                        <p>Yoga</p>
                                    </div>
                                    <p>Dyplomowana nauczycielka yogi z ponad 10-letnim doświadczeniem. Z wykształcenia psycholog i instruktor rekreacji ruchowej o specjalności ćwiczenia psychofizyczne w oparciu o system hatha yogi.</p>
                                </div>
                            </div>

                            <div>
                                <div className="trainer">
                                    <div className="trainer-photo">
                                    <GatsbyImage image={data.kuba.childImageSharp.gatsbyImageData} alt=""/>                                        
                                        <h4>Jakub Głąb</h4>
                                        <p>Treningi dla mężczyzn i par</p>
                                    </div>
                                    <p>Mistrz Polski w Fitness Sylwetkowym, wielokrotny Mistrz Polski, Europy i Świata w Karate Tradycyjnym. Z zawodu trener personalny dietetyk, instruktor samoobrony i fitness, certyfikowany trener TRX.</p>
                                </div>
                            </div>
                        </Slider>
                        <button className="slider-btn prev" onClick={handlePrev}><img src={arrow} className='rotate180deg' alt="wstecz" /></button>
                        <button className="slider-btn next" onClick={handleNext}><img src={arrow} alt="dalej" /></button>
                    </div>
                </Container>
            </section>

            <section className="diet6">
                <Container>
                    <Row>
                        <Col md={{size: 8, offset: 2}}>
                            <h2>Trójkąt Mocy</h2>
                            <p>Przepisy w aplikacji oparte są na Trójkącie Mocy – jedzeniu funkcjonalnym, dzięki któremu możesz wpłynąć na wybrane przez siebie obszary. Sama decydujesz, na które obszary chcesz wpłynąć najbardziej – możesz wybrać jedną, dwie lub wszystkie trzy opcje.</p>

                            <div className="box">
                                <Row className="align-items-center">
                                    <Col md="6">
                                        <img src={powerTriangle} className="img-fluid" alt="" />
                                    </Col>
                                    <Col md="6" className="text-left">
                                        <p><strong>Umysł</strong> – gdy masz problemy z&nbsp;pamięcią lub koncentracją</p>
                                        <p><strong>Ciało</strong> – gdy zależy Ci na wzroście wydolności i poprawie kondycji</p>
                                        <p><strong>Zmysły</strong> – jeśli chcesz usprawnić swoje pożycie</p>
                                    </Col>
                                </Row>
                            </div>
                    
                            <Button
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('buy')
                                    )
                                }>
                                Wystarczy, że zaczniesz!
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="diet7">
                <img src={mozdziez} className="mozdziez" alt="" />
                <img src={pomidory} className="pomidory" alt="" />

                <Container>
                    <h2 className="text-center">Dieta, dzięki której pokochasz zdrowe odżywianie</h2>
                    <p className="text-center">Każdy z nas jest inny. Mamy odmienne upodobania, przyzwy­czajenia i nietolerancje pokarmowe. Dlatego w aplikacji znajdziesz propozycje aż czterech różnych diet. Wybierz tę, która pasuje do Ciebie najlepiej! Proste przepisy pozwolą Ci na wyjątkowo szybkie przygotowanie zdrowych posiłków, dzięki czemu zaoszczędzisz czas, pieniądze i energię.</p>
                    <br/><br/>
                    <Row className="mt-md-5">
                        <Col md="5" className="d-none d-md-block">
                            <div className="position-relative">
                                <div className="circle1" />
                                <div className="circle2" />
                                <img src={makaron} className="circle img-fluid" alt="" />
                            </div>
                        </Col>

                        <Col md={{size: 6, offset: 1}}>

                            <Card className={isOpen === 1 ? 'is-open' : ''}>
                                <CardBody>
                                    <h4 onClick={() => toggle(1)} aria-hidden="true" >
                                        Classic 5 posiłków
                                        <span className="dropdown-arrow ml-2">
                                            <svg
                                                aria-hidden="true"
                                                data-prefix="fas"
                                                data-icon="chevron-down"
                                                className="svg-inline"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                                />
                                            </svg>
                                        </span>    
                                    </h4>
                                    
                                    <Collapse isOpen={isOpen === 1}>
                                        <p>Wyklucza pszenicę i jej pochodne oraz mleko krowie. Źródłem białka w diecie są głównie: mięso, ryby, nabiał i jaja. </p>
                                    </Collapse>
                                </CardBody>
                            </Card>

                            <Card className={isOpen === 2 ? 'is-open' : ''}>
                                <CardBody>
                                    <h4 onClick={() => toggle(2)} aria-hidden="true" >
                                        Low IG
                                        <span className="dropdown-arrow ml-2">
                                            <svg
                                                aria-hidden="true"
                                                data-prefix="fas"
                                                data-icon="chevron-down"
                                                className="svg-inline"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                                />
                                            </svg>
                                        </span>    
                                    </h4>
                                    <Collapse isOpen={isOpen === 2}>
                                        <p>Dieta bazuje na produktach posiadających niski indeks glikemiczny (IG). Wyklucza pszenicę i jej pochodne oraz mleko krowie. Idealna opcja, jeśli chcesz ograniczyć spożycie cukrów prostych i zadbać o uregulowanie poziomu cukru we krwi.</p>
                                    </Collapse>            
                                </CardBody>
                            </Card>

                            <Card className={isOpen === 3 ? 'is-open' : ''}>
                                <CardBody>
                                    <h4 onClick={() => toggle(3)} aria-hidden="true" >
                                        Smart 3 posiłki
                                        <span className="dropdown-arrow ml-2">
                                            <svg
                                                aria-hidden="true"
                                                data-prefix="fas"
                                                data-icon="chevron-down"
                                                className="svg-inline"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                                />
                                            </svg>
                                        </span>    
                                    </h4>
                                    <Collapse isOpen={isOpen === 3}>
                                        <p>Najszybszy i najłatwiejszy wariant diety. Wspiera działanie Trójkąta Mocy, wykluczając przy tym pszenicę i jej pochodne oraz mleko krowie. Idealny wariant dla osób chcących spędzać w kuchni minimum czasu.</p>
                                    </Collapse>
                                </CardBody>
                            </Card>
                            
                            <Card className={isOpen === 4 ? 'is-open' : ''}>
                                <CardBody>
                                    <h4 onClick={() => toggle(4)} aria-hidden="true" >
                                        Vege 5 posiłków
                                        <span className="dropdown-arrow ml-2">
                                            <svg
                                                aria-hidden="true"
                                                data-prefix="fas"
                                                data-icon="chevron-down"
                                                className="svg-inline"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                                />
                                            </svg>
                                        </span>    
                                    </h4>
                                    <Collapse isOpen={isOpen === 4}>
                                    <p>Wyklucza mięso, ryby, owoce morza i mleko krowie. Uwzględnia natomiast jaja i nasiona roślin strączkowych. Węglowodany złożone dostarcza w postaci chleba żytniego, kasz i produktów bezglutenowych. Odpowiednią podaż wapnia zapewnia dzięki serom i mlecznym napojom fermentowanym (takim jak: kefiry, jogurty, czy maślanki). Uwzględnia również napoje roślinne oraz nabiał kozi.</p>
                                    </Collapse>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                        
                </Container>
            </section>

            <section className="diet8">
                <Container>
                    <h2>Tysiące prostych i pysznych przepisów.</h2>
                    <h3>Tylko od Ciebie zależy, czy z nich skorzystasz!</h3>
                </Container>

                <Col>
                    <Nav pills={true}>
                        <NavLink
                            onClick={() => toggleActive(1)}
                            className={isActive === 1 ? 'active' : ''}
                        >
                            Classic 5 posiłków
                        </NavLink>

                        <NavLink
                            onClick={() => toggleActive(2)}
                            className={isActive === 2 ? 'active' : ''}
                        >
                            Low IG
                        </NavLink>

                        <NavLink
                            onClick={() => toggleActive(3)}
                            className={isActive === 3 ? 'active' : ''}
                        >
                            Smart 3 posiłki
                        </NavLink>

                        <NavLink
                            onClick={() => toggleActive(4)}
                            className={isActive === 4 ? 'active' : ''}
                        >
                            Vege 5 posiłków
                        </NavLink>
                    </Nav>

                    {isActive === 1 ? (
                        <CardDeck>
                            <Card>
                                <CardImg top width="100%" src={diet11} alt="" />
                                <CardBody>
                                    <h4>Śniadanie</h4>
                                    <h3>Kremowa jajecznica z awokado, pomidorkami i ziołami</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 416 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" />15 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>

                            <Card>
                            <CardImg top width="100%" src={diet12} alt="" />
                                <CardBody>
                                    <h4>Przekąska poranna</h4>
                                    <h3>Aksamitne smoothie na bazie sezonowych owoców</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 248 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 5 min</span>
                                    <span className="tag">Umysł</span>
                                </CardFooter>
                            </Card>
        
                            <Card>
                                <CardImg top width="100%" src={diet13} alt="" />
                                <CardBody>
                                    <h4>Obiad</h4>
                                    <h3>Delikatny łosoś w szpinakowym sosie z makaronem żytnim</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 536 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 20 min</span>
                                    <span className="tag">Umysł</span>
                                </CardFooter>
                            </Card>

                            <Card>
                                <CardImg top width="100%" src={diet14} alt="" />
                                <CardBody>
                                    <h4>Przekąska wieczorna</h4>
                                    <h3>Pieczywo żytnie na zakwasie, kremowa pasta z sera feta, suszonych pomidorów i bazylii</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 177 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 5 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>
                        </CardDeck>
                    ) : ''}

                    {isActive === 2 ? (
                        <CardDeck>
                            <Card>
                                <CardImg top width="100%" src={diet21} alt="" />
                                <CardBody>
                                    <h4>Śniadanie</h4>
                                    <h3>Kolorowa jaglanka z musem owocowym, posypana prażonymi orzechami</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 380 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" />20 min</span>
                                    <span className="tag">Umysł</span>
                                </CardFooter>
                            </Card>

                            <Card>
                            <CardImg top width="100%" src={diet22} alt="" />
                                <CardBody>
                                    <h4>Przekąska poranna</h4>
                                    <h3>Mieszanka chrupiących orzechów </h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" />  224 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 1 min</span>
                                    <span className="tag">Umysł</span>
                                </CardFooter>
                            </Card>
        
                            <Card>
                                <CardImg top width="100%" src={diet23} alt="" />
                                <CardBody>
                                    <h4>Obiad</h4>
                                    <h3>Orientalny stir-fry z makaronem ryżowym, soczystą wołowiną i sezonowymi warzywami</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 487 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 25 min</span>
                                    <span className="tag">Zmysły</span>
                                </CardFooter>
                            </Card>

                            <Card>
                                <CardImg top width="100%" src={diet24} alt="" />
                                <CardBody>
                                    <h4>Przekąska wieczorna</h4>
                                    <h3>Chałwowe kulki mocy z nutką korzennych przypraw</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 262 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 10 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>


                            <Card>
                                <CardImg top width="100%" src={diet25} alt="" />
                                <CardBody>
                                    <h4>Kolacja</h4>
                                    <h3>Sałatka z pieczonym batatem i komosą, polana aromatycznym, cytrynowo-ziołowym dressingiem</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 360 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 20 min</span>
                                    <span className="tag">Zmysły</span>
                                </CardFooter>
                            </Card>
                        </CardDeck>
                    ) : ''}

                    {isActive === 3 ? (
                        <CardDeck>
                            <Card>
                                <CardImg top width="100%" src={diet31} alt="" />
                                <CardBody>
                                    <h4>Śniadanie</h4>
                                    <h3>Orzeźwiająca sałatka z kremowym awokado, jajkiem i słodko-kwaśnym sosem</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 414 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" />15 min</span>
                                    <span className="tag">Umysł</span>
                                </CardFooter>
                            </Card>

                            <Card>
                            <CardImg top width="100%" src={diet32} alt="" />
                                <CardBody>
                                    <h4>Przekąska poranna</h4>
                                    <h3>Naturalnie słodki koktajl z buraka i malin</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 192 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 15 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>
        
                            <Card>
                                <CardImg top width="100%" src={diet33} alt="" />
                                <CardBody>
                                    <h4>Obiad</h4>
                                    <h3>Soczysta pierś grillowanego kurczaka, delikatne spaghetti z warzyw</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 372 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 25 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>

                            <Card>
                                <CardImg top width="100%" src={diet34} alt="" />
                                <CardBody>
                                    <h4>Przekąska wieczorna</h4>
                                    <h3>Surówka z selera  z dodatkiem słodkich winogron i orzechów włoskich, skropiona oliwą </h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 268 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 15 min</span>
                                    <span className="tag">Umysł</span>
                                </CardFooter>
                            </Card>

                            <Card>
                                <CardImg top width="100%" src={diet35} alt="" />
                                <CardBody>
                                    <h4>Kolacja</h4>
                                    <h3>Kasza gryczana z suszonymi pomidorami i aromatycznymi ziołami</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 330 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 15 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>
                        </CardDeck>
                    ) : ''}

                    {isActive === 4 ? (
                        <CardDeck>
                            <Card>
                                <CardImg top width="100%" src={diet41} alt="" />
                                <CardBody>
                                    <h4>Śniadanie</h4>
                                    <h3>Mocno czekoladowa owsianka z orzeźwiającymi, sezonowymi owocami </h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 320 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" />15 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>

                            <Card>
                            <CardImg top width="100%" src={diet42} alt="" />
                                <CardBody>
                                    <h4>Przekąska poranna</h4>
                                    <h3>Kremowy hummus z chrupiącymi warzywami </h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 230 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 10 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>
        
                            <Card>
                                <CardImg top width="100%" src={diet43} alt="" />
                                <CardBody>
                                    <h4>Obiad</h4>
                                    <h3>Kokosowy dal z soczewicą i orientalnymi przyprawami</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 516 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 30 min</span>
                                    <span className="tag">Zmysły</span>
                                </CardFooter>
                            </Card>

                            <Card>
                                <CardImg top width="100%" src={diet44} alt="" />
                                <CardBody>
                                    <h4>Przekąska wieczorna</h4>
                                    <h3>Pieczywo żytnie z kremowym awokado i fetą</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 240 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 5 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>

                            <Card>
                                <CardImg top width="100%" src={diet45} alt="" />
                                <CardBody>
                                    <h4>Kolacja</h4>
                                    <h3>Puszysty omlet z grillowanymi warzywami i dodatkiem koziego sera</h3>
                                </CardBody>
                                <CardFooter>
                                    <span><img src={iconKcal} alt="" /> 260 kcal</span>
                                    <span className="mx-2"><img src={iconTime} alt="" /> 15 min</span>
                                    <span className="tag">Ciało</span>
                                </CardFooter>
                            </Card>
                        </CardDeck>
                    ) : ''}
                </Col>
            </section>

            <section className="diet9">
                <Container>
                    <Row className="align-items-center">
                        <Col md="7">
                            <h2>Przygotowanie posiłków jeszcze nigdy nie było tak proste</h2>
                            <p>W aplikacji znajdziesz przepisy video, dzięki czemu bez problemu i krok po kroku przygotujesz swój posiłek.</p>
                        </Col>

                        <Col md="5">
                        <video
                                className="video img-fluid"
                                aria-hidden="true"
                                muted
                                autoPlay
                                playsInline
                                loop
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="diet16">
                        <Container>
                            <Row>
                                <Col xs={{size: 12, order: 2}} md={{size: 6, order: 1}}>
                                    <GatsbyImage className="img-fluid" image={data.favMealScreen.childImageSharp.gatsbyImageData} alt=""/>
                                </Col>
                                <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}} className="d-flex flex-column justify-content-center">
                                    <h2 className='text-center text-md-left'>
                                        Pokochałaś jedno z dań w swojej diecie?
                                    </h2>
                                    <p className='mb-5'>
                                        Zapisuj przepisy ulubionych posiłków, by móc dodać je do swojego jadłospisu, gdy tylko masz na to ochotę.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
            </section>
            <section className="diet10">
                    <Container>
                        <Row>
                            <Col>
                                <h2>Wymieniaj składniki i całe dania</h2>
                                <p>Nie przepadasz za jakimś składnikiem, a może cały proponowany posiłek nie jest w Twoim guście? Wymień go, wybierając z listy proponowanych zamienników. Dzięki temu - dieta będzie w pełni uwzględniać Twoje preferencje i każdego dnia smaczna!</p>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-5">
                            <Col md={{size: 8, offset: 3}}>
                                <GatsbyImage className="img-fluid" image={data.change_ingredients.childImageSharp.gatsbyImageData} alt=""/>
                            </Col>
                        </Row>
                    </Container>

            </section>
            <section className="diet11">
                <Container>
                    <Row className="align-items-center">
                        <Col md="7">
                            <h2>Zapewnisz swojemu organizmowi optymalne nawodnienie</h2>
                            <p>Woda usuwa toksyny z organizmu, oczyszcza, dodaje energii i chęci do działania, orzeźwia, poprawia metabolizm, uśmierza ból, nawilża skórę. To właśnie dzięki niej w naszym organizmie zachodzą wszystkie istotne czynności życiowe.</p>
                            <p>Korzystając z funkcji Twoje nawodnienie dostępnej w aplikacji już zawsze będziesz mieć pewność, że dostarczasz swojemu organizmowi wystarczającą ilość wody.</p>
                        </Col>
                        <Col md="5" className="text-center">
                            <div className="circle" />
                            <img src={woda} className="img-fluid" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="diet12">
                <Container>
                    <h2>Twój cel jest w zasięgu ręki</h2>
                    <Button
                        onClick={() =>
                            scrollToElement(
                                document.getElementById('buy')
                            )
                        }>
                        Wystarczy, że zaczniesz!
                    </Button>
                </Container>
            </section>

            <section className="diet13">
                <Container>
                    <h2 className="text-center">Czy to dieta, z którą każdy dojdzie do celu?</h2>
                    <Row className="align-items-center my-5">
                        
                        <Col md={{size: 7, order: 2}}>
                            <p>Każdy rodzaj diety w Diet & Training by Ann możesz stosować niezależnie od tego, czy chcesz zrzucić zbędne kilogramy, przytyć, utrzymać masę ciała, a nawet w sytuacji, gdy Twoim celem jest zwiększenie masy mięśniowej. Wszystko dlatego, że przed dopasowaniem diety sama ustalasz swój upragniony cel. Trenujesz? Zostanie to uwzględnione w Twoim jadłospisie!</p>
                        </Col>
                        <Col md={{size: 5, order: 1}}>
                            <img src={salatka} className="img-fluid" alt="" />
                        </Col>
                    </Row>

                    <Row className="align-items-center my-5">
                        <Col md="7">
                            <p>Korzystając z diety w aplikacji Diet & Training by Ann wyeliminujesz ze swojego jadłospisu żywność wysokoprzetworzoną, produkty GMO, gazowane i słodzone napoje, fast-foody, sklepowe słodycze pełne cukrów prostych i tłuszczów trans. Ich miejsce zajmą świeże, sezonowe owoce i warzywa, które przygotować można na mnóstwo różnych, ciekawych sposobów.</p>
                        </Col>
                        <Col md="5">
                            <img src={skladniki} className="img-fluid" alt="" />
                        </Col>
                    </Row>

                    <Row className="align-items-center my-5">
                        
                        <Col md={{size: 7, order: 2}}>
                            <p>Będziesz także korzystać z bogactwa smaku, jakie dają naturalne przyprawy i zioła. W jadłospisach uwzględniono wyłącznie zdrowe tłuszcze, takie jak na przykład olej kokosowy, masło ghee, oliwę z oliwek. Korzystając z aplikacji krok po kroku wyrobisz nowe, zdrowe nawyki żywieniowe, które zostaną z Tobą na zawsze!</p>
                        </Col>
                        <Col md={{size: 5, order: 1}}>
                            <img src={przyprawy} className="img-fluid" alt="" />
                        </Col>
                    </Row>


                    <Alert>
                        Pamiętaj też, że w trakcie aktywnego abonamentu możesz zmienić ustawienia swojej diety nieskończoną ilość razy. Ważne, żeby słuchać sygnałów, które wysyła do Ciebie Twoje ciało i odpowiednio na nie reagować!
                    </Alert>

                </Container>
            </section>

            <section className="diet14">
                <Container>
                    <h2>Nie trać swojej szansy na wymarzoną sylwetkę</h2>
                    <Button
                        onClick={() =>
                            scrollToElement(
                                document.getElementById('buy')
                            )
                        }>
                        Wystarczy, że zaczniesz!
                    </Button>
                </Container>
            </section>

            <section className="diet15">
                <Container>
                    <Row>
                        <Col md={{size: 10, offset: 1}}>
                            <h3>Dołącz do naszego teamu!</h3>

                            <div className="position-relative">
                                <div className="circle1" />
                                <div className="circle2" />
                                <div className="video-box">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe
                                            className="embed-responsive-item"
                                            src="https://player.vimeo.com/video/409705784"
                                            title="video"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

    
            <section className="buy" id="buy">
                <Container>
                    <Row className="align-items-center">
                        <Col md="4">
                            <h3>PROMOCJA LIMITOWANA</h3>
                            <h2>do -33%</h2>
                            <h4>Spróbuj diety i treningów w cenach, które się nie powtórzą</h4>
                        </Col>
    
                        <Col md="8">
                            <CardDeck className="multi-products">
                                <SaleProductProvider tag='landing:dieta' source='diet' promoCode="dieta30">
                                    <SaleProducts color='white' showThumbnail={false} showShortDescription={false} />
                                </SaleProductProvider>
                            </CardDeck>
                        </Col>
                    </Row>        
                </Container>
            </section>
        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "diet/header_desktop.jpg" }) {
            ...fluidImage1920
        }
        anna: file(relativePath: { eq: "homepage-new/anna-test.png" }) {...fluidImage1920}
        ewelina: file(relativePath: { eq: "homepage-new/ewelina-test.png" }) {...fluidImage1920}
        mariola: file(relativePath: { eq: "homepage-new/mariola-test.png" }) {...fluidImage1920}
        kuba: file(relativePath: { eq: "homepage-new/kuba-test.png" }) {...fluidImage1920}
        jj: file(relativePath: { eq: "homepage-new/jj-test.png" }) {...fluidImage1920}
        change_ingredients: file(relativePath: { eq: "diet/change_ingredients.png" }) {...fluidImage1920}
        favMealScreen: file(relativePath: { eq: "diet/fav_meals_screen.png" }) {...fluidImage1920}
    }
`;
